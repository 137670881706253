import { template as template_769ae29e0934405d97d57024c86fba73 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_769ae29e0934405d97d57024c86fba73(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
