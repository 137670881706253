import { template as template_8d405ac22a6e44f58ab5b81e69d361e3 } from "@ember/template-compiler";
const FKLabel = template_8d405ac22a6e44f58ab5b81e69d361e3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
