import { template as template_16fa581fe29e4b60a8b5fbb2709026bb } from "@ember/template-compiler";
const FKControlMenuContainer = template_16fa581fe29e4b60a8b5fbb2709026bb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
